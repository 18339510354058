import React, { useState } from "react"
import ListModerationItems from "../../media-output/lists/list-moderation-items/list-moderation-items"
import classNames from "classnames"
import styles from "./duplicate-content.module.scss"
import { useForm } from "react-form"
import Checkbox from "../../common/form/check-box/check-box"
import moderation from "../../../services/moderation"
import _ from "lodash"
import Button from "../../common/button/button"
import { StateProvider } from "@appbaseio/reactivesearch"
import { connect } from "@appbaseio/reactivesearch/lib/utils"
import { clearValues } from "@appbaseio/reactivecore/lib/actions"
import auth from "../../../services/auth"

const DuplicateContent = () => {
  // for whatever reason I couldn't get this property to work with useState() inside of a form
  // const checkState = { validated: false }
  const [isOpen, toggleOpen] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [currentSearchState, setCurrentSearchState] = useState({})
  const [items, setItems] = useState([])



  var obj = { allItemsRead: moderation.getAllItemsReadState() }

  const defaultValues = React.useMemo(() => obj, [])
  console.log("ModerateContent allItemsRead: ", defaultValues.allItemsRead)

  const { Form, meta } = useForm({
    defaultValues: defaultValues,
    validatePristine: true,
    validate: (values, instance) => {
      setSubmitAttempted(false)
      return false
    },

    onSubmit: values => {
      var allItems = moderation.getItems()
      console.log('OnSubmit')
      var approvedItems = _.filter(allItems, item => {
        if (item.itemRead) {
          return item.itemRead === true;
        }
      })
      console.log("approvedItems===>", approvedItems)

      if (approvedItems.length > 0) {
        moderation.approve(approvedItems, true).then(function (result) {
          var _items = moderation.getItems()
          var updatedItems = []
          _.forEach(_items, item => {
            if (_.findIndex(approvedItems, { _id: item._id }) !== -1) {
              item.deletedLocally = true
            }
            updatedItems.push(item)
          })



          // we need to refresh the items array reference so that the list properly updates the checkbox toggle... Possibly a better way to do this?
          setItems(updatedItems)
          // TODO ideally we would want to use clearValues to refresh the list but I'm not sure why it isn't working
          // clearValues()
        })
      }
    },
    debugForm: false,
  })

  const onConfirmMarkAsRead = () => {
    setSubmitAttempted(true)
  }

  const onSelectAllChange = event => {
    var _items = moderation.getItems()

    //const currentValue = moderation.getAllItemsReadState()
    const newValue = event.target.checked
    console.log("onSelectAllChange newValue:", newValue)
    var updatedItems = []
    _.forEach(_items, item => {
      item.itemRead = newValue
      updatedItems.push(item)
    })

    //  let ii=array.findIndex(item => item.id === moderation.getAllItemsReadState());
    //  itemsArray[ii].itemRead=newValue
    //  updatedItems.push(itemsArray)

    console.log("updatedItems:", updatedItems)
    // we need to refresh the items array reference so that the list properly updates the checkbox toggle... Possibly a better way to do this?
    setItems(updatedItems)
  }

  const getHeaderMarkUp = () => {
    //if (auth.isBranchOrOrgModerating()) {
    return (
      <>
        <Form>
          <div className={styles.selectAllSection}>
            <Checkbox
              field="allItemsRead"
              label="Select All"
              onChange={onSelectAllChange}
            ></Checkbox>

            <StateProvider
              render={({ searchState }) => {
                setCurrentSearchState(searchState)
                return (
                  <Button
                    id="confirmMarkAsRead"
                    label="Keep Selected"
                    btnStyle="primary"
                    btnSize="multipleWords"
                    type="submit"
                    onClick={onConfirmMarkAsRead}
                  ></Button>
                )
              }}
            />
          </div>
        </Form>
      </>
    )
    //   } else {
    //     return ""
    //   }
  }



  return (
    <>
      <div className={classNames(styles.wrapper, isOpen ? styles.fixed : "")}>
        <h2>Duplicate Media</h2>
        <div className={styles.container}>
          {getHeaderMarkUp()}
          <div className={styles.cardHeadWrap}>
            {getHeaderMarkUp() ? <div className={styles.formHead}></div> : ""}
            <ul className={styles.cardHead}>
              <li></li>
              <li>Title</li>
              <li>Type</li>
              <li>File Type</li>
              <li>Size</li>
              <li>Date</li>
              <li>Actions</li>
            </ul>
          </div>
          <div className={styles.medias}>
            <ListModerationItems items={items} getDuplicates={true}/>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchtoProps = dispatch => ({
  clearValues: () => dispatch(clearValues()),
})

export default connect(null, mapDispatchtoProps)(DuplicateContent)

