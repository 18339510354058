import React, { useState } from "react"
import DuplicateContent from "../../components/search/duplicate-content/duplicate-content"
import InnerSearchLayout from "../../components/layout/inner-search-layout/inner-search-layout"
import styles from "./index.module.scss"
import auth from "../../services/auth"

const DuplicateMedia = props => {
  return (
    <InnerSearchLayout location={props.location} searchPath="searchDuplicate">
      <DuplicateContent />
    </InnerSearchLayout>
  )
}

export default DuplicateMedia
